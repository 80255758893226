@import "../../variables.scss";

.page.teammate {
  .teammate-info {
    display: flex;
    justify-content: center;
  }
  .teammate-feedback {
    label {
      display: block;
      margin-bottom: 3px;
    }
    .form-row {
      margin-top: 15px;
    }
    select,
    textarea {
      padding: 5px;
      width: 100%;
    }
    button {
      margin-top: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      background-color: $green1;
      color: $white1;
      border: none;
      border-radius: 5px;
      font-size: 1.125rem;
    }
  }
}
