@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;600;700&display=swap");

body {
  background-color: $green3;
  margin: 0;
  padding: 0;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 400;

  * {
    box-sizing: border-box;
  }
  .page {
    max-width: 900px;
    margin: 0 auto;
    padding: 0;

    header {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: $green1;
      text-align: center;
      color: $white1;

      .name {
        font-weight: 600;
      }
      // in case the address is long (i.e. suite #)
      .address {
        display: flex;
        flex-direction: column;
      }
    }
    section {
      padding: 40px 20px;
    }
  }
}
