@import "../../variables.scss";

.location-header-component {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $green1;
  text-align: center;
  color: $white1;

  .name {
    font-weight: 600;
  }
  // in case the address is long (i.e. suite #)
  .address {
    display: flex;
    flex-direction: column;
  }
}
