@import "../../variables.scss";

.profile-photo-component {
  width: 90px;
  height: 90px;
  position: relative;

  .border {
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &::before {
      content: attr(data-initials);
      width: 110px;
      height: 110px;
      border: 5px solid #1cb75e;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
    }
  }
  .image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $white1;
  }
  span {
    display: block;
    margin-top: 20px;
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
  }
}
