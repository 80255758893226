.profile-photo-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  .photo-wrapper {
    margin-bottom: 10px;
    width: 124px;
    height: 124px;
    border: 1px solid #c0cdd9;
    border-radius: 60px;
    box-shadow: 5px 7px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "Add Photo";
      width: 100%;
      height: 25%;
      padding-top: 4px;
      position: absolute;
      bottom: 0;
      background-color: rgba(124, 132, 149, 0.75);
      color: #fff;
      font-size: 0.8125em;
      text-align: center;
      box-sizing: border-box;
      z-index: 2;
    }
    .default {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 75%;
      transform: translate(-50%, -50%);

      &.hide {
        display: none;
      }
    }
    .preview {
      width: 124px;
      height: 124px;
      position: absolute;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
    }
    input {
      position: relative;
      left: -9999px;
    }
    .initials {
      position: absolute;
      top: 45%;
      left: 50%;
      font-size: 2em;
      letter-spacing: 1px;
      color: #333;
      transform: translate(-50%, -50%);
    }
  }
  &.show {
    display: inline-block;

    .photo-wrapper {
      cursor: default;

      &::after {
        display: none;
      }
    }
  }
}
