@import "../../variables.scss";

.page.location {
  padding-bottom: 50px;

  section {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .instructions {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.4375rem;
    text-align: center;
  }
  .location-feedback {
    label {
      display: block;
      margin-bottom: 3px;
    }
    .form-row {
      margin-top: 15px;
    }
    select,
    textarea {
      padding: 5px;
      width: 100%;
    }
    button {
      margin-top: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      background-color: $green1;
      color: $white1;
      border: none;
      border-radius: 5px;
      font-size: 1.125rem;
    }
    .teammates-select .profile-photo-component {
      width: 30px;
      height: 30px;

      .border::before {
        width: 36px;
        height: 36px;
        border: 2px solid #1cb75e;
        font-size: 0.9375rem;
      }
      span {
        display: none;
      }
    }
    textarea {
      resize: none;
    }
  }
  .teammates-block {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    justify-items: center;
    column-gap: 40px;
    row-gap: 80px;

    a {
      color: $gray1;
      text-decoration: none;
    }
  }
}
